import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const FormEditPaciente = () => {
  const [name, setName] = useState("");
  const [tipoDocumentoIdentificacion, settipoDocumentoIdentificacion] = useState("");
  const [numDocumentoIdentificacion, setnumDocumentoIdentificacion] = useState("");
  const [fechaNacimiento, setfechaNacimiento] = useState("");
  const [codSexo, setcodSexo] = useState("");
  const [codPaisResidencia, setcodPaisResidencia] = useState("");
  const [codMunicipioResidencia, setcodMunicipioResidencia] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const getPacienteById = async () => {
      try {
        const response = await axios.get(
          `https://backend.madresegura.co/pacientes/${id}`
        );
        setName(response.data.name);
        settipoDocumentoIdentificacion(response.data.tipoDocumentoIdentificacion);
        setnumDocumentoIdentificacion(response.data.numDocumentoIdentificacion);
        setfechaNacimiento(response.data.fechaNacimiento);
        setcodSexo(response.data.codSexo);
        setcodPaisResidencia(response.data.codPaisResidencia);
        setcodMunicipioResidencia(response.data.codMunicipioResidencia);
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };
    getPacienteById();
  }, [id]);

  const updatePaciente = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(`https://backend.madresegura.co/pacientes/${id}`, {
        name: name,
        tipoDocumentoIdentificacion: tipoDocumentoIdentificacion,
        numDocumentoIdentificacion: numDocumentoIdentificacion,
        fechaNacimiento: fechaNacimiento,
        codSexo: codSexo,
        codPaisResidencia: codPaisResidencia,
        codMunicipioResidencia: codMunicipioResidencia,
      });
      navigate("/pacientes");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <h1 className="title has-text-black">Pacientes</h1>
      <h2 className="subtitle has-text-black">Editar Paciente</h2>
      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={updatePaciente}>
              <p className="has-text-centered">{msg}</p>
              <div className="field">
                <label className="label">Nombre</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Nombre Paciente"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">TipoDocumentoIdentificacion</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={tipoDocumentoIdentificacion}
                    onChange={(e) => settipoDocumentoIdentificacion(e.target.value)}
                    placeholder="tipoDocumentoIdentificacion"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">NumDocumentoIdentificacion</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={numDocumentoIdentificacion}
                    onChange={(e) => setnumDocumentoIdentificacion(e.target.value)}
                    placeholder="numDocumentoIdentificacion"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">fechaNacimiento</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={fechaNacimiento}
                    onChange={(e) => setfechaNacimiento(e.target.value)}
                    placeholder="fechaNacimiento"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label"> codSexo</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={ codSexo}
                    onChange={(e) => setcodSexo(e.target.value)}
                    placeholder=" codSexo"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">codPaisResidencia</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={codPaisResidencia}
                    onChange={(e) => setcodPaisResidencia(e.target.value)}
                    placeholder="codPaisResidencia"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">codMunicipioResidencia</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={codMunicipioResidencia}
                    onChange={(e) => setcodMunicipioResidencia(e.target.value)}
                    placeholder="codMunicipioResidencia"
                  />
                </div>
              </div>

              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-success">
                    Actualizar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormEditPaciente;