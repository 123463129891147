import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const FormAddHistoriaClinica = () => {
  const { pacienteId } = useParams();
  const [fechaConsulta, setFechaConsulta] = useState("");
  const [codigoConsulta, setCodigoConsulta] = useState("");
  const [motivoConsulta, setMotivoConsulta] = useState("");
  const [diagnosticoPrincipal, setDiagnosticoPrincipal] = useState("");
  const [diagnosticoSecundario, setDiagnosticoSecundario] = useState("");
  const [procedimiento, setProcedimiento] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();

  const saveHistoriaClinica = async (e) => {
    e.preventDefault();
    try {
      await axios.post("https://backend.madresegura.co/historias", {
        pacienteUuid: pacienteId,  // Asegúrate de enviar el UUID
        fechaConsulta,
        codigoConsulta,
        motivoConsulta,
        diagnosticoPrincipal,
        diagnosticoSecundario,
        procedimiento
      });
      navigate("/historias");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <h1 className="title has-text-black">Historia Clínica</h1>
      <h2 className="subtitle has-text-black">Agregar Nueva Historia Clínica</h2>
      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={saveHistoriaClinica}>
              <p className="has-text-centered">{msg}</p>
              <div className="field">
                <label className="label">Paciente ID</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={pacienteId}
                    readOnly
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Fecha de Consulta</label>
                <div className="control">
                  <input
                    type="date"
                    className="input"
                    value={fechaConsulta}
                    onChange={(e) => setFechaConsulta(e.target.value)}
                    placeholder="Fecha de Consulta"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Código de Consulta</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={codigoConsulta}
                    onChange={(e) => setCodigoConsulta(e.target.value)}
                    placeholder="Código de Consulta"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Motivo de Consulta</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={motivoConsulta}
                    onChange={(e) => setMotivoConsulta(e.target.value)}
                    placeholder="Motivo de Consulta"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Diagnóstico Principal</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={diagnosticoPrincipal}
                    onChange={(e) => setDiagnosticoPrincipal(e.target.value)}
                    placeholder="Diagnóstico Principal"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Diagnóstico Secundario</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={diagnosticoSecundario}
                    onChange={(e) => setDiagnosticoSecundario(e.target.value)}
                    placeholder="Diagnóstico Secundario"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Procedimiento</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={procedimiento}
                    onChange={(e) => setProcedimiento(e.target.value)}
                    placeholder="Procedimiento"
                  />
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-success">
                    Guardar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormAddHistoriaClinica;
