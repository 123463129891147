import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const HistoriaClinicaList = () => {
  const [historias, setHistorias] = useState([]);
  useEffect(() => {
    getHistorias();
  }, []);

  const getHistorias = async () => {
    try {
      const response = await axios.get("https://backend.madresegura.co/historias");
      setHistorias(response.data);
    } catch (error) {
      console.error("Error fetching historias:", error);
    }
  };

  const deleteHistoriaClinica = async (historiaId) => {
    try {
      await axios.delete(`https://backend.madresegura.co/historias/${historiaId}`);
      getHistorias(); // Refresca la lista después de eliminar
    } catch (error) {
      console.error("Error deleting historia clínica:", error);
    }
  };

  return (
    <div>
      <h1 className="title has-text-black">Historias Clínicas</h1>
      <h2 className="subtitle has-text-black">Lista de Historias Clínicas</h2>
      <div className="table-container">
        <table className="table is-striped is-fullwidth">
          <thead>
            <tr>
              <th>No</th>
              <th>Paciente</th>
              <th>Fecha de Consulta</th>
              <th>Código de Consulta</th>
              <th>Motivo de Consulta</th>
              <th>Diagnóstico Principal</th>
              <th>Diagnóstico Secundario</th>
              <th>Procedimiento</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {historias.map((historia, index) => (
              <tr key={historia.uuid}>
                <td>{index + 1}</td>
                <td>{historia.paciente.name}</td>
                <td>{historia.fechaConsulta}</td>
                <td>{historia.codigoConsulta}</td>
                <td>{historia.motivoConsulta}</td>
                <td>{historia.diagnosticoPrincipal}</td>
                <td>{historia.diagnosticoSecundario}</td>
                <td>{historia.procedimiento}</td>
                <td>
                  <Link
                    to={`/historias/edit/${historia.uuid}`}
                    className="button is-small is-info"
                  >
                    Editar
                  </Link>
                  <button
                    onClick={() => deleteHistoriaClinica(historia.uuid)}
                    className="button is-small is-danger"
                  >
                    Eliminar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default HistoriaClinicaList;
