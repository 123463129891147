import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const FormEditHistoriaClinica = () => {
  const [paciente, setPaciente] = useState(null); // Para almacenar los datos del paciente
  const [fechaConsulta, setFechaConsulta] = useState("");
  const [codigoConsulta, setCodigoConsulta] = useState("");
  const [motivoConsulta, setMotivoConsulta] = useState("");
  const [diagnosticoPrincipal, setDiagnosticoPrincipal] = useState("");
  const [diagnosticoSecundario, setDiagnosticoSecundario] = useState("");
  const [procedimiento, setProcedimiento] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const getHistoriaClinicaById = async () => {
      try {
        const response = await axios.get(`https://backend.madresegura.co/historias/${id}`);
        const historia = response.data;
        setPaciente(historia.paciente); // Asignar el objeto paciente
        setFechaConsulta(historia.fechaConsulta);
        setCodigoConsulta(historia.codigoConsulta);
        setMotivoConsulta(historia.motivoConsulta || "");
        setDiagnosticoPrincipal(historia.diagnosticoPrincipal);
        setDiagnosticoSecundario(historia.diagnosticoSecundario || "");
        setProcedimiento(historia.procedimiento || "");
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };
    getHistoriaClinicaById();
  }, [id]);

  const updateHistoriaClinica = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(`https://backend.madresegura.co/historias/${id}`, {
        pacienteId: paciente.uuid, // Usar el UUID del paciente
        fechaConsulta: fechaConsulta,
        codigoConsulta: codigoConsulta,
        motivoConsulta: motivoConsulta,
        diagnosticoPrincipal: diagnosticoPrincipal,
        diagnosticoSecundario: diagnosticoSecundario,
        procedimiento: procedimiento,
      });
      navigate("/historias");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <h1 className="title has-text-black">Historia Clínica</h1>
      <h2 className="subtitle has-text-black">Editar Historia Clínica</h2>
      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={updateHistoriaClinica}>
              <p className="has-text-centered">{msg}</p>
              <div className="field">
                <label className="label">Paciente</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={paciente ? paciente.name : ""}
                    readOnly
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Fecha de Consulta</label>
                <div className="control">
                  <input
                    type="date"
                    className="input"
                    value={fechaConsulta}
                    onChange={(e) => setFechaConsulta(e.target.value)}
                    placeholder="Fecha de Consulta"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Código de Consulta</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={codigoConsulta}
                    onChange={(e) => setCodigoConsulta(e.target.value)}
                    placeholder="Código de Consulta"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Motivo de Consulta</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={motivoConsulta}
                    onChange={(e) => setMotivoConsulta(e.target.value)}
                    placeholder="Motivo de Consulta"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Diagnóstico Principal</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={diagnosticoPrincipal}
                    onChange={(e) => setDiagnosticoPrincipal(e.target.value)}
                    placeholder="Diagnóstico Principal"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Diagnóstico Secundario</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={diagnosticoSecundario}
                    onChange={(e) => setDiagnosticoSecundario(e.target.value)}
                    placeholder="Diagnóstico Secundario"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Procedimiento</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={procedimiento}
                    onChange={(e) => setProcedimiento(e.target.value)}
                    placeholder="Procedimiento"
                  />
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-success">
                    Actualizar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormEditHistoriaClinica;
