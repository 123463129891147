import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";

const PacienteList = () => {
  const [pacientes, setPacientes] = useState([]);
  const { user } = useSelector((state) => state.auth);
  
  useEffect(() => {
    getPacientes();
  }, []);

  const getPacientes = async () => {
    try {
      const response = await axios.get("https://backend.madresegura.co/pacientes");
      setPacientes(response.data);
    } catch (error) {
      console.error("Error fetching pacientes:", error);
    }
  };

  const deletePaciente = async (pacienteId) => {
    try {
      await axios.delete(`https://backend.madresegura.co/pacientes/${pacienteId}`);
      getPacientes();
    } catch (error) {
      console.error("Error deleting paciente:", error);
    }
  };

  return (
    <div>
      <h1 className="title has-text-black">Pacientes</h1>
      <h2 className="subtitle has-text-black">Lista de Pacientes</h2>
      <Link to="/pacientes/add" className="button is-primary mb-2">
        Agregar un paciente nuevo
      </Link>
      <div className="table-container">
        <table className="table is-striped is-fullwidth">
          <thead>
            <tr>
              <th>No</th>
              <th>Nombre</th>
              <th>Tipo Documento Identificación</th>
              <th>Número Documento Identificación</th>
              <th>Fecha Nacimiento</th>
              <th>Sexo</th>
              <th>País Residencia</th>
              <th>Municipio Residencia</th>
              <th>Creado por</th>
              <th>Acciones</th>
              {user && (user.role === "admin" || user.role === "doctor") && (
                <th>Historia Clínica</th>
              )}
            </tr>
          </thead>
          <tbody>
            {pacientes.map((paciente, index) => (
              <tr key={paciente.uuid}>
                <td>{index + 1}</td>
                <td>{paciente.name}</td>
                <td>{paciente.tipoDocumentoIdentificacion}</td>
                <td>{paciente.numDocumentoIdentificacion}</td>
                <td>{paciente.fechaNacimiento}</td>
                <td>{paciente.codSexo}</td>
                <td>{paciente.codPaisResidencia}</td>
                <td>{paciente.codMunicipioResidencia}</td>
                <td>{paciente.user.name}</td>
                <td>
                  <Link
                    to={`/pacientes/edit/${paciente.uuid}`}
                    className="button is-small is-info"
                  >
                    Editar
                  </Link>
                  <button
                    onClick={() => deletePaciente(paciente.uuid)}
                    className="button is-small is-danger"
                  >
                    Eliminar
                  </button>
                </td>
                {user && (user.role === "admin" || user.role === "doctor") && (
                  <td>
                    <Link
                      to={`/historias/add/${paciente.uuid}`}
                      className="button is-small is-success"
                    >
                      Historia Clínica
                    </Link>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PacienteList;
