import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const FormAddPaciente = () => {
  const [name, setName] = useState("");
  const [tipoDocumentoIdentificacion, settipoDocumentoIdentificacion] = useState("CC");
  const [numDocumentoIdentificacion, setnumDocumentoIdentificacion] = useState("");
  const [fechaNacimiento, setfechaNacimiento] = useState("");
  const [codSexo, setcodSexo] = useState("M");
  const [codPaisResidencia, setcodPaisResidencia] = useState("");
  const [codMunicipioResidencia, setcodMunicipioResidencia] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();

  const savePaciente = async (e) => {
    e.preventDefault();
    try {
      await axios.post("https://backend.madresegura.co/pacientes", {
        name: name,
        tipoDocumentoIdentificacion: tipoDocumentoIdentificacion,
        numDocumentoIdentificacion: numDocumentoIdentificacion,
        fechaNacimiento: fechaNacimiento,
        codSexo: codSexo,
        codPaisResidencia: codPaisResidencia,
        codMunicipioResidencia: codMunicipioResidencia,
      });
      navigate("/pacientes");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <h1 className="title has-text-black">Pacientes</h1>
      <h2 className="subtitle has-text-black">Agregar Nuevo Paciente</h2>
      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={savePaciente}>
              <p className="has-text-centered">{msg}</p>
              <div className="field">
                <label className="label">Nombre</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Nombre Paciente"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">TipoDocumentoIdentificacion</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                        value={tipoDocumentoIdentificacion}
                        onChange={(e) => settipoDocumentoIdentificacion(e.target.value)}
                      >
                        <option value="CC" >Cédula ciudadanía</option>
                        <option value="CE" >Cédula de extranjería</option>
                        <option value="CD" >Carné diplomático</option>
                        <option value="PA" >Pasaporte</option>
                        <option value="SC" >Salvoconducto</option>
                        <option value="PE" >Permiso Especial de Permanencia</option>
                        <option value="RC" >Registro civil</option>
                        <option value="TI" >Tarjeta de identidad </option>
                        <option value="CN" >Certificado de nacido vivo </option>
                        <option value="AS" >Adulto sin identificar </option>
                        <option value="MS" >Menor sin identificar</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="field">
                <label className="label">NumDocumentoIdentificacion</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={numDocumentoIdentificacion}
                    onChange={(e) => setnumDocumentoIdentificacion(e.target.value)}
                    placeholder="numDocumentoIdentificacion"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">fechaNacimiento</label>
                <div className="control">
                  <input
                    type="date"
                    className="input"
                    value={fechaNacimiento}
                    onChange={(e) => setfechaNacimiento(e.target.value)}
                    placeholder="fechaNacimiento"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label"> codSexo</label>
                <div className="control">
                <div className="select is-fullwidth">
                  <select
                      value={codSexo}
                      onChange={(e) => setcodSexo(e.target.value)}
                    >
                      <option value="M" >Masculino</option>
                      <option value="F" >Femenino</option>
                  </select>
                </div>                 
                </div>
              </div>
              <div className="field">
                <label className="label">codPaisResidencia</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={codPaisResidencia}
                    onChange={(e) => setcodPaisResidencia(e.target.value)}
                    placeholder="codPaisResidencia"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">codMunicipioResidencia</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={codMunicipioResidencia}
                    onChange={(e) => setcodMunicipioResidencia(e.target.value)}
                    placeholder="codMunicipioResidencia"
                  />
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-success">
                    Guardar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormAddPaciente;