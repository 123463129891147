import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./components/Login";
import Users from "./pages/Users";
import Pacientes from "./pages/Pacientes";
import AddUser from "./pages/AddUser";
import EditUser from "./pages/EditUser";
import AddPaciente from "./pages/AddPaciente";
import EditPaciente from "./pages/EditPaciente";
import Historias from "./pages/Historias";
import AddHistoria from "./pages/AddHistoria";
import EditHistoria from "./pages/EditHistoria";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/users" element={<Users />} />
          <Route path="/users/add" element={<AddUser />} />
          <Route path="/users/edit/:id" element={<EditUser />} />
          <Route path="/pacientes" element={<Pacientes />} />
          <Route path="/pacientes/add" element={<AddPaciente />} />
          <Route path="/pacientes/edit/:id" element={<EditPaciente />} />
          <Route path="/historias" element={<Historias />} />
          <Route path="/historias/add/:pacienteId" element={<AddHistoria />} />
          <Route path="/historias/edit/:id" element={<EditHistoria />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
